import React from "react";
import { Table, Button, Dropdown, Space, Select } from "antd";
import { getPageHeader } from "../../../Common/Utils";
import { DownOutlined, EyeOutlined } from "@ant-design/icons";

const History = ({
  bookingList,
  getBookingDetails,
  getPatientDetails,
  staticDropdownData,
  updateInsuranceStatus,
  activeTab,
}) => {
  const parentColumns = [
    {
      title: "Patient",
      dataIndex: "identifier",
      key: "identifier",
      render: (_, record) =>
        `${record?.childrenData[0]?.firstName} ${record?.childrenData[0]?.lastName}`,
    },
  ];

  const childColumns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Scheduled For",
      dataIndex: "bookingDate",
    },
    {
      title: "Slot",
      dataIndex: "slot",
    },
    {
      title: "Status",
      dataIndex: "bookingStatus",
    },
    {
      title: "Payment",
      dataIndex: "stripePaymentStatus",
      render: (_, record) => {
        return record?.stripePaymentStatus
          ? record?.stripePaymentStatus === "NA"
            ? record?.stripePaymentStatus
            : getPageHeader(record?.stripePaymentStatus)
          : "NA";
      },
    },
    {
      title: "Insurance",
      dataIndex: "insuranceStatus",
      width: 185,
      render: (_, record) => (
        <Select
          size="small"
          value={
            record?.insuranceStatus && record?.insuranceStatus !== ""
              ? record?.insuranceStatus
              : null
          }
          placeholder="Please Select"
          options={staticDropdownData?.common?.insuranceStatus}
          className="full-width"
          onChange={(value) => updateInsuranceStatus(record, value)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Dropdown
          className="text-center"
          menu={{
            items: [
              {
                key: "1",
                label: "Booking Details",
                icon: <EyeOutlined />,
                onClick: () => getBookingDetails(record),
              },
              {
                key: "2",
                label: "Patient Details",
                icon: <EyeOutlined />,
                onClick: () => getPatientDetails(record),
              },
            ],
          }}
        >
          <Button size="small">
            <Space>
              Actions
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
  ];

  const parentData = bookingList?.map((children, index) => ({
    key: index,
    childrenData: children,
  }));

  return (
    <Table
      columns={parentColumns}
      dataSource={parentData}
      expandable={{
        expandIcon: ({ expanded, onExpand, record }) => (
          <span
            id={record?.key}
            onClick={(e) => onExpand(record, e)}
            style={{
              display: "inline-block",
              width: "24px",
              height: "24px",
              fontSize: "20px",
              lineHeight: "24px",
              textAlign: "center",
              backgroundColor: "#464646",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            {expanded ? "-" : "+"}
          </span>
        ),
        expandedRowRender: (record) => (
          <Table
            columns={
              activeTab === "cancelled"
                ? childColumns?.filter(
                    (item) => item?.dataIndex !== "insuranceStatus"
                  )
                : childColumns
            }
            dataSource={record.childrenData}
            rowKey="bookingID"
            pagination={false}
          />
        ),
        rowExpandable: (record) => record.childrenData.length > 0,
      }}
      pagination={false}
    />
  );
};

export default History;
