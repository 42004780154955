import { Button, Col, Form, Input, Row } from "antd";
import { useState } from "react";
import { postApis } from "../../../Common/Apis";
import { BUTTONS, NOTIFICATIONS, VALIDATIONS } from "../../../Common/Constants";
import { providerViewPatientAudiologyGtDataUrl } from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";
import useNotify from "../../../Common/Notify";
import { renderKeyValueDetails } from "../../../Common/Utils";

const QuickSin = ({ audiologyGtDetails, profileDetails, isMobile }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { notify } = useNotify();

  const onFinish = async (values) => {
    const params = {
      ...values,
      token: profileDetails?.token,
      type: "QuickSIN",
    };
    setLoading(true);
    await postApis(providerViewPatientAudiologyGtDataUrl, params)
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return Object.keys(audiologyGtDetails?.quickSin)?.length > 0 ? (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {renderKeyValueDetails(
          isMobile,
          "Total Score",
          audiologyGtDetails?.quickSin?.totalScore
        )}
      </Col>
    </Row>
  ) : (
    <Form
      initialValues={{}}
      form={form}
      layout="vertical"
      size="large"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      validateMessages={{
        required: VALIDATIONS.DEFAULT.BLANK,
      }}
    >
      <Form.Item
        label="Total Score"
        name="totalScore"
        size="large"
        rules={[
          {
            required: true,
          },
          {
            validator: (_, value) => {
              if (!isNaN(value) && value >= 0 && value <= 100) {
                return Promise.resolve();
              }
              return Promise.reject("Invalid input (0 to 100)");
            },
          },
        ]}
      >
        <Input placeholder="0-100" max={100} />
      </Form.Item>

      <Row className="flex-center">
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Form.Item className="mt1">
            <Button type="primary" htmlType="submit" block disabled={loading}>
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default QuickSin;
