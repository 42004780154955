import { Button, Col, DatePicker, Divider, Form, Input, Row } from "antd";
import { useState } from "react";
import { postApis } from "../../../Common/Apis";
import { BUTTONS, NOTIFICATIONS, VALIDATIONS } from "../../../Common/Constants";
import { providerViewPatientAudiologyGtDataUrl } from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";
import useNotify from "../../../Common/Notify";
import {
  disableFutureDates,
  renderKeyValueDetails,
} from "../../../Common/Utils";

const Audiometry = ({ audiologyGtDetails, profileDetails, isMobile }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { notify } = useNotify();

  const onFinish = async (values) => {
    const params = {
      ...values,
      token: profileDetails?.token,
      type: "Audiometry",
      testDate: values?.testDate?.format("MM/DD/YYYY"),
    };
    setLoading(true);
    await postApis(providerViewPatientAudiologyGtDataUrl, params)
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return Object.keys(audiologyGtDetails?.audiometry)?.length > 0 ? (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {renderKeyValueDetails(
          isMobile,
          "Left Ear Level 250Hz",
          audiologyGtDetails?.audiometry?.Left250
        )}
        {renderKeyValueDetails(
          isMobile,
          "Left Ear Level 500Hz",
          audiologyGtDetails?.audiometry?.Left500
        )}
        {renderKeyValueDetails(
          isMobile,
          "Left Ear Level 1000Hz",
          audiologyGtDetails?.audiometry?.Left1000
        )}
        {renderKeyValueDetails(
          isMobile,
          "Left Ear Level 2000Hz",
          audiologyGtDetails?.audiometry?.Left2000
        )}
        {renderKeyValueDetails(
          isMobile,
          "Left Ear Level 4000Hz",
          audiologyGtDetails?.audiometry?.Left4000
        )}
        {renderKeyValueDetails(
          isMobile,
          "Left Ear Level 8000Hz",
          audiologyGtDetails?.audiometry?.Left8000
        )}
        {renderKeyValueDetails(
          isMobile,
          "Left Ear Peak Pressure",
          audiologyGtDetails?.audiometry?.LeftPP
        )}
        {renderKeyValueDetails(
          isMobile,
          "Left Ear WRS",
          audiologyGtDetails?.audiometry?.LeftWRS
        )}
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {renderKeyValueDetails(
          isMobile,
          "Right Ear Level 250Hz",
          audiologyGtDetails?.audiometry?.Right250
        )}
        {renderKeyValueDetails(
          isMobile,
          "Right Ear Level 500Hz",
          audiologyGtDetails?.audiometry?.Right500
        )}
        {renderKeyValueDetails(
          isMobile,
          "Right Ear Level 1000Hz",
          audiologyGtDetails?.audiometry?.Right1000
        )}
        {renderKeyValueDetails(
          isMobile,
          "Right Ear Level 2000Hz",
          audiologyGtDetails?.audiometry?.Right2000
        )}
        {renderKeyValueDetails(
          isMobile,
          "Right Ear Level 4000Hz",
          audiologyGtDetails?.audiometry?.Right4000
        )}
        {renderKeyValueDetails(
          isMobile,
          "Right Ear Level 8000Hz",
          audiologyGtDetails?.audiometry?.Right8000
        )}
        {renderKeyValueDetails(
          isMobile,
          "Right Ear Peak Pressure",
          audiologyGtDetails?.audiometry?.RightPP
        )}
        {renderKeyValueDetails(
          isMobile,
          "Right Ear WRS",
          audiologyGtDetails?.audiometry?.RightWRS
        )}
      </Col>
    </Row>
  ) : (
    <Form
      initialValues={{}}
      form={form}
      layout="vertical"
      size="large"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      validateMessages={{
        required: VALIDATIONS.DEFAULT.BLANK,
      }}
    >
      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} sm={24} md={24} lg={12} xl={11}>
          <Divider orientation="center" plain>
            <div className="flex-center">
              <div
                className="mr05"
                style={{
                  backgroundColor: "#3ea1ff",
                  height: "15px",
                  width: "15px",
                  borderRadius: "1rem",
                  border: "solid 1px #3ea1ff",
                }}
              ></div>{" "}
              <div>Left Ear: Hearing Threshold Levels(dB)</div>
            </div>
          </Divider>
          <Form.Item
            label="250Hz"
            name="Left250"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d*(\.\d*)?$/,
                message: "Invalid number",
              },
            ]}
          >
            <Input placeholder="Eg: 20" maxLength={7} />
          </Form.Item>
          <Form.Item
            label="500Hz"
            name="Left500"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d*(\.\d*)?$/,
                message: "Invalid number",
              },
            ]}
          >
            <Input placeholder="Eg: 12" maxLength={7} />
          </Form.Item>
          <Form.Item
            label="1000Hz"
            name="Left1000"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d*(\.\d*)?$/,
                message: "Invalid number",
              },
            ]}
          >
            <Input placeholder="Eg: 15" maxLength={7} />
          </Form.Item>
          <Form.Item
            label="2000Hz"
            name="Left2000"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d*(\.\d*)?$/,
                message: "Invalid number",
              },
            ]}
          >
            <Input placeholder="Eg: 13" maxLength={7} />
          </Form.Item>
          <Form.Item
            label="4000Hz"
            name="Left4000"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d*(\.\d*)?$/,
                message: "Invalid number",
              },
            ]}
          >
            <Input placeholder="Eg: 11" maxLength={7} />
          </Form.Item>
          <Form.Item
            label="8000Hz"
            name="Left8000"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d*(\.\d*)?$/,
                message: "Invalid number",
              },
            ]}
          >
            <Input placeholder="Eg: 14" maxLength={7} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={11}>
          <Divider orientation="center" plain>
            <div className="flex-center">
              <div
                className="mr05"
                style={{
                  color: "red",
                  fontWeight: 600,
                }}
              >
                X
              </div>{" "}
              <div>Right Ear: Hearing Threshold Levels(dB)</div>
            </div>
          </Divider>

          <Form.Item
            label="250Hz"
            name="Right250"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d*(\.\d*)?$/,
                message: "Invalid number",
              },
            ]}
          >
            <Input placeholder="Eg: 12" maxLength={7} />
          </Form.Item>
          <Form.Item
            label="500Hz"
            name="Right500"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d*(\.\d*)?$/,
                message: "Invalid number",
              },
            ]}
          >
            <Input placeholder="Eg: 20" maxLength={7} />
          </Form.Item>
          <Form.Item
            label="1000Hz"
            name="Right1000"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d*(\.\d*)?$/,
                message: "Invalid number",
              },
            ]}
          >
            <Input placeholder="Eg: 10" maxLength={7} />
          </Form.Item>
          <Form.Item
            label="2000Hz"
            name="Right2000"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d*(\.\d*)?$/,
                message: "Invalid number",
              },
            ]}
          >
            <Input placeholder="Eg: 12" maxLength={7} />
          </Form.Item>
          <Form.Item
            label="4000Hz"
            name="Right4000"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d*(\.\d*)?$/,
                message: "Invalid number",
              },
            ]}
          >
            <Input placeholder="Eg: 16" maxLength={7} />
          </Form.Item>
          <Form.Item
            label="8000Hz"
            name="Right8000"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d*(\.\d*)?$/,
                message: "Invalid number",
              },
            ]}
          >
            <Input placeholder="Eg: 18" maxLength={7} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]} justify="space-between">
        <Divider orientation="left" plain>
          Word Recognition Score(%)
        </Divider>
        <Col xs={24} sm={24} md={24} lg={12} xl={11}>
          <Form.Item
            label="Left Ear WRS"
            name="LeftWRS"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d*(\.\d*)?$/,
                message: "Invalid number",
              },
              {
                validator: (_, value) => {
                  if (!isNaN(value) && value >= 0 && value <= 100) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Invalid percentage (0 to 100)");
                },
              },
            ]}
          >
            <Input placeholder="100" max={100} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={11}>
          <Form.Item
            label="Right Ear WRS"
            name="RightWRS"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (!isNaN(value) && value >= 0 && value <= 100) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Invalid percentage (0 to 100)");
                },
              },
            ]}
          >
            <Input placeholder="100" max={100} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]} justify="space-between">
        <Divider orientation="left" plain>
          Tympanometry(daPa)
        </Divider>
        <Col xs={24} sm={24} md={24} lg={12} xl={11}>
          <Form.Item
            label="Left Ear Peak Pressure"
            name="LeftPP"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d+$/,
                message: "Invalid number",
              },
            ]}
          >
            <Input placeholder="5" maxLength={4} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={11}>
          <Form.Item
            label="Right Ear Peak Pressure"
            name="RightPP"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^-?\d+$/,
                message: "Invalid number",
              },
            ]}
          >
            <Input placeholder="11" maxLength={4} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} sm={24} md={24} lg={12} xl={11}>
          <Form.Item
            label="Test Date"
            name="testDate"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              placeholder="MM/DD/YYYY"
              format="MM/DD/YYYY"
              inputReadOnly
              disabledDate={disableFutureDates}
              allowClear={false}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="flex-center">
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Form.Item className="mt1">
            <Button type="primary" htmlType="submit" block disabled={loading}>
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Audiometry;
