import { Button, Col, Form, Input, Row } from "antd";
import { BUTTONS, VALIDATIONS } from "../../../Common/Constants";
import Loader from "../../../Common/Loader";
import Popup from "../../../Common/Popup";
const { TextArea } = Input;

const Product = ({
  form,
  loading,
  isModalOpen,
  handleCancel,
  onFinish,
  onFinishFailed,
}) => {
  return (
    <Popup
      title={"Add/Edit Product"}
      open={isModalOpen}
      cancel={handleCancel}
      footer={null}
      width={600}
    >
      <Form
        initialValues={{}}
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      >
        <Form.Item
          label="Product"
          name="product"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Product Name" />
        </Form.Item>

        <Form.Item
          label="Amount"
          name="amount"
          rules={[
            {
              required: true,
            },
            {
              pattern: /^[0-9]+$/,
              message: "Only numbers are allowed",
            },
          ]}
        >
          <Input placeholder="Amount" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea rows={3} placeholder="Product Description" />
        </Form.Item>
        <Row className="flex-center mt1">
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Button type="primary" htmlType="submit" block disabled={loading}>
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Popup>
  );
};

export default Product;
