import { Button, Col, Empty, QRCode, Row } from "antd";
import { useState } from "react";
import { getApis } from "../../Common/Apis";
import { providerViewPatientAudiologyPaymentsUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";

const Payments = ({ audiologyDetails, profileDetails }) => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState();

  const getPaymentLinkHandler = async () => {
    setLoading(true);
    await getApis(providerViewPatientAudiologyPaymentsUrl, {
      cancelUrl: window.location.href,
      successUrl: window.location.href,
      token: profileDetails?.token,
    })
      .then((response) => {
        setLoading(false);
        setUrl(response?.data?.paymentUrl);
      })
      .catch(() => setLoading(false));
  };

  return audiologyDetails ? (
    <div className="pd1 text-center">
      <div>
        You are viewing this page because this service is enabled in the admin
        settings.
      </div>
      {url ? (
        <>
          <div className="bold mt2">Scan Here</div>
          <div className="flex-center mt1">
            <QRCode value={url} />
          </div>
          <div className="mt1">OR</div>
          <Row className="flex-center mt1">
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Button
                type="primary"
                size="large"
                onClick={() => window.location.assign(url)}
              >
                Pay Here
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <Button
          type="primary"
          className="mt1"
          onClick={() => getPaymentLinkHandler()}
          disabled={loading}
        >
          <Loader loading={loading} text={"Generate Payment Link"} />
        </Button>
      )}
    </div>
  ) : (
    <Empty
      imageStyle={{
        height: 70,
      }}
      description="Payment will be accessible once the insights have been generated."
    />
  );
};

export default Payments;
