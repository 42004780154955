import {
  ClockCircleOutlined,
  CloseOutlined,
  DoubleRightOutlined,
  DownOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space, Table } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBookingDetails } from "../../../Reducers/bookingsSlice";
import { BUTTONS, ROUTES, USER_TYPES } from "../Constants";
import { extractClinic, getPageHeader } from "../Utils";

const Active = ({
  bookingList,
  userType,
  showRescheduleModal,
  getBookingDetails,
  getPatientDetails,
  showCancelConfirm,
  locationType,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onContinueClickHandler = async (record) => {
    dispatch(setBookingDetails(null));
    const data = {
      oldBookingDate: record?.bookingDate,
      bookingDate: record?.bookingDate,
      location: record?.location,
      returnDate: record?.returnDate,
      slot: record?.slot,
      days: record?.days,
      paymentStatus: record?.paymentStatus,
      weeks: record?.weeks,
    };

    localStorage.setItem(
      "bookingDetails",
      JSON.stringify({ ...record, ...data })
    );

    dispatch(setBookingDetails({ ...record, ...data }));
    navigate(
      `${ROUTES.PATIENT.SLEEP_STUDY.PATH}?clinic=${extractClinic(
        record?.bookingType
      )}&step=1`
    );
  };

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "fullName",
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Scheduled For",
      dataIndex: "bookingDate",
    },
    {
      title: "Return Date",
      dataIndex: "returnDate",
      render: (_, record) => record?.returnDate || "NA",
    },
    {
      title: "Slot",
      dataIndex: "slot",
    },
    {
      title: "Type",
      dataIndex: "bookingType",
    },
    {
      title: "Status",
      dataIndex: "bookingStatus",
    },
    {
      title: "Booked By",
      dataIndex: "bookedBy",
    },
    {
      title: "Payment",
      dataIndex: "stripePaymentStatus",
      render: (_, record) => {
        return record?.stripePaymentStatus
          ? record?.stripePaymentStatus === "NA"
            ? record?.stripePaymentStatus
            : getPageHeader(record?.stripePaymentStatus)
          : "NA";
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => {
        return userType === USER_TYPES.PATIENT &&
          record?.bookingType === "SFOTO-FOTONA-NIGHTLASE" ? (
          <Button
            size="small"
            className="green-btn mr05"
            onClick={() => getBookingDetails(record)}
          >
            <>
              <EyeOutlined className="mr05" />
              {BUTTONS.VIEW}
            </>
          </Button>
        ) : userType === USER_TYPES.PATIENT &&
          record?.bookingStatus === "PENDING" ? (
          <Button
            className="mr05 blue-btn"
            size="small"
            onClick={() => onContinueClickHandler(record)}
            icon={<DoubleRightOutlined />}
          >
            {BUTTONS.CONTINUE}
          </Button>
        ) : (
          <Dropdown
            className="flex-center"
            menu={{
              items: [
                {
                  key: "1",
                  label: "Reschedule",
                  icon: <ClockCircleOutlined />,
                  onClick: () => showRescheduleModal(record),
                },
                {
                  key: "2",
                  label: "Booking Details",
                  icon: <EyeOutlined />,
                  onClick: () => getBookingDetails(record),
                },
                {
                  key: "3",
                  label: "Cancel",
                  icon: <CloseOutlined />,
                  onClick: () => showCancelConfirm(record),
                },
                {
                  key: "4",
                  label: "Patient Details",
                  icon: <EyeOutlined />,
                  onClick: () => getPatientDetails(record),
                },
              ].filter((item) =>
                userType === USER_TYPES.PATIENT ? item.key !== "4" : item
              ),
            }}
          >
            <Button block size="small" name="activeRow">
              <Space>
                Actions
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div data-testid="sleepActive">
      <Table
        bordered
        rowKey={(record) => `${record?.bookingID}-${record?.bookingDate}`}
        columns={
          userType === USER_TYPES.PATIENT
            ? columns.filter(
                (column) =>
                  ![
                    "fullName",
                    "bookedBy",
                    "sleepStudyCompleted",
                    "insuranceStatus",
                  ].includes(column.dataIndex)
              )
            : locationType === "fotona"
            ? columns.filter(
                (column) =>
                  !["returnDate", "bookingType"].includes(column.dataIndex)
              )
            : userType === USER_TYPES.CLINIC_ADMIN
            ? columns.filter(
                (column) => !["bookingType"].includes(column.dataIndex)
              )
            : columns
        }
        dataSource={bookingList}
        scroll={{ x: "100%" }}
        pagination={false}
      />
    </div>
  );
};

export default Active;
