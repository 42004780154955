import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Table,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { postApis } from "../../Common/Apis";
import { BUTTONS, VALIDATIONS } from "../../Common/Constants";
import { providerViewPatientFeNoUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";
import { disableFutureDates, displayDateTime } from "../../Common/Utils";
import dayjs from "dayjs";

const FeNo = ({ token, feNoDetails, getFeNoData }) => {
  const [loading, setLoading] = useState(false);
  const [timestamp, setTimestamp] = useState(null);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    const formData = {
      ...values,
      ...{ token: token, collectedTime: timestamp },
    };
    await postApis(providerViewPatientFeNoUrl, formData)
      .then(() => {
        setLoading(false);
        getFeNoData(true);
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const handleDateChange = (date, dateString) => {
    if (dateString) {
      setTimestamp(Date.parse(dateString));
    } else {
      setTimestamp(null);
    }
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const currentDateTime = moment();
      setTimestamp(Date.parse(currentDateTime));
      form.setFieldsValue({ collectedTime: dayjs(currentDateTime) });
    } else {
      setTimestamp(null);
      form.setFieldsValue({ collectedTime: null });
    }
  };

  const columns = [
    {
      title: "FeNO Value",
      dataIndex: "value",
    },
    {
      title: "Collected Time",
      dataIndex: "collectedTime",
      render: (_, record) => displayDateTime(record?.collectedTime),
    },
  ];

  return (
    <div>
      <Row justify={"center"} className="mt2">
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <Card>
            <Form
              form={form}
              initialValues={{}}
              size="large"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              validateMessages={{
                required: VALIDATIONS.DEFAULT.BLANK,
              }}
            >
              <Form.Item
                label="FeNO(ppb)"
                name="value"
                size="large"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: VALIDATIONS.PATTERN.NUMERIC,
                  },
                ]}
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 6 },
                  xl: { span: 6 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 18 },
                  xl: { span: 18 },
                }}
              >
                <Input placeholder="FeNO Value" />
              </Form.Item>

              <Form.Item
                label="Collected On"
                size="large"
                required
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 6 },
                  xl: { span: 6 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 18 },
                  xl: { span: 18 },
                }}
              >
                <Row gutter={[16, 16]} className="flex-center">
                  <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <Checkbox onChange={handleCheckboxChange}>Now</Checkbox>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                    <Form.Item
                      name="collectedTime"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      noStyle
                    >
                      <DatePicker
                        showTime
                        format="MM-DD-YYYY HH:mm:ss"
                        placeholder="MM-DD-YYYY HH:MM:SS"
                        onChange={handleDateChange}
                        disabledDate={disableFutureDates}
                        popupClassName="custom-dropdown"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>

              <Row justify={"center"}>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <Form.Item className="mt1">
                    <Button type="primary" htmlType="submit" disabled={loading}>
                      <Loader loading={loading} text={BUTTONS.SUBMIT} />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
      <Table
        className="mt2"
        bordered
        rowKey={(record) => new Date(record?.collectedTime).getTime()}
        columns={columns}
        dataSource={feNoDetails}
        scroll={{ x: "100%" }}
        pagination={false}
      />
    </div>
  );
};

export default FeNo;
