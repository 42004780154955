import { FundOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Row } from "antd";
import { logEvent } from "firebase/analytics";
import { signOut } from "firebase/auth";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import moment from "moment";
import { analytics, auth, remoteConfig } from "../../firebase";
import { getApis } from "./Apis";
import { ROUTES, SERVICES, USER_TYPES, VALIDATIONS } from "./Constants";
import { patientUpdateTokenUrl } from "./Endpoints";
import Loader from "./Loader";

export const processedPhoneNumber = (values) => {
  const phoneNumber = values?.phoneNumber?.replace(/[()-]/g, "");
  return values?.code?.concat(phoneNumber);
};

export const processedFaxNumber = (code, faxNumber) => {
  const formattedFaxNumber = faxNumber?.replace(/[()-]/g, "");
  return code?.concat(formattedFaxNumber);
};

export const setAccessTokens = (data) => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");
  localStorage.setItem("authToken", data?.access_token);
  localStorage.setItem("refreshToken", data?.refresh_token);
};

export const reloadPatientPrograms = async (fromRing) => {
  await getApis(patientUpdateTokenUrl, {
    os: getOS(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  })
    .then((response) => {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("authToken");
      localStorage.setItem("authToken", response?.data?.access_token);
      localStorage.setItem("refreshToken", response?.data?.refresh_token);
      localStorage.removeItem("userDetails");
      setTimeout(() => {
        window.location.assign(
          fromRing
            ? `${ROUTES.LANDING.PATH}?fromRing=true`
            : ROUTES.LANDING.PATH
        );
      }, 1000);
    })
    .catch(() => {});
};

export const setUserDetails = (data) => {
  localStorage.setItem("userDetails", JSON.stringify(data));
};

export const validatePhoneLength = (_, value) => {
  if (value && value.replace(/[^\d]/g, "").length !== 10) {
    return Promise.reject(VALIDATIONS.DEFAULT.INVALID_NUMBER);
  }
  return Promise.resolve();
};

export const tagColors = [
  "#01C6B2",
  "#00A73E",
  "#FFCD00",
  "#FF7D01",
  "#28696D",
  "#1CB4BF",
  "#47596C",
];

export const logout = (eventName) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userType = localStorage.getItem("userType");
  localStorage.clear();
  if (process.env.NODE_ENV !== "test") {
    signOut(auth)
      .then(() => {})
      .catch(() => {});
    logAnalyticsEvent(eventName, {
      userType: userType?.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()),
      data: userDetails?.email,
    });
    if (userType === USER_TYPES.PATIENT) {
      postMessageToMobileApp({ loggedIn: false }, "patientLogin");
    }
  }
};

export const displayUserName = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  if (userDetails?.firstName && userDetails?.lastName) {
    return `${userDetails?.firstName} ${userDetails?.lastName}`;
  } else {
    return userDetails?.email;
  }
};

export const getPageHeader = (path) => {
  let parts = path?.split("/");
  let lastPart = parts?.[parts?.length - 1];
  const formattedText = decodeURIComponent(lastPart)
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
    .replace(/([A-Z])/g, " $1")
    .trim();
  return formattedText === "Slit" ? "Allergy Drops" : formattedText;
};

export const capitaliseWord = (word) => {
  return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
};

export const titleizeWord = (text) => {
  return text
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const logAnalyticsEvent = (eventName, eventInfo) => {
  const basicInfo = {
    logTime: new Date().getTime(),
  };
  if (process.env.REACT_APP_ENV === "production") {
    logEvent(analytics, eventName, { ...basicInfo, ...eventInfo });
  }
};

export const displayOnlyDate = (datetime) => {
  const mmddyyyyPattern = /^\d{2}-\d{2}-\d{4}$/;

  let formattedInputDate = datetime;

  if (isAndroidOrIosApp() && mmddyyyyPattern.test(datetime)) {
    const [month, day, year] = datetime.split("-");
    formattedInputDate = `${year}-${month}-${day}`;
  }

  const formattedDate = new Date(formattedInputDate)
    .toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    })
    .replace(/\//g, "/");
  return formattedDate;
};

export const displayDateWithText = (datetime) => {
  const formattedDate = new Date(datetime).toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  return formattedDate.replace(/(\d+)\s(\w+)\s(\d+)/, "$1 $2, $3");
};

export const displayDateTime = (dateTime) => {
  return new Date(dateTime)
    .toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    })
    .replace(/\//g, "/");
};

export const addViewButton = (path, text) => {
  return (
    <Button
      size="small"
      key="link"
      href={path}
      target="_blank"
      className="blue-btn"
    >
      {text}
    </Button>
  );
};

export const displayDateWithSuffix = (dateString) => {
  const date = new Date(dateString);

  const options = { weekday: "long", month: "long", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  const day = date.getDate();
  const suffix =
    day === 1 || day === 21 || day === 31
      ? "st"
      : day === 2 || day === 22
      ? "nd"
      : day === 3 || day === 23
      ? "rd"
      : "th";

  return formattedDate.replace(day, `${day}${suffix}`);
};

const remoteConfigData = async (listName) => {
  try {
    // remoteConfig.settings.minimumFetchIntervalMillis = 60000;
    await fetchAndActivate(remoteConfig);
    const configValue = getValue(remoteConfig, listName);
    const configString = configValue?.asString();
    return configString ? JSON.parse(configString) : null;
  } catch (error) {
    return null;
  }
};

export const getRemoteConfigData = (name) => {
  return remoteConfigData("META_KEYS").then((result) => {
    const configData = result?.[process.env.REACT_APP_ENV];
    return remoteConfigData(configData?.[name]).then((response) => {
      return response;
    });
  });
};

export const renderKeyValueDetails = (isMobile, label, value) => {
  if (value) {
    return (
      <Row gutter={[16, 16]} className="mb1">
        <Col span={24}>
          <Flex wrap="wrap" gap="small" vertical={isMobile ? true : false}>
            <div className="blue">{label}: </div>
            <div className="light-green bold">
              {Array.isArray(value) ? value.join(", ") : value}
            </div>
          </Flex>
        </Col>
      </Row>
    );
  }
};

export const validateDate = (_, value) => {
  const dateFormat = "MM/DD/YYYY";

  if (!value) {
    return Promise.resolve();
  }
  const date = moment(value).format(dateFormat);
  const isValidDate = moment(date, dateFormat, true).isValid();

  if (!isValidDate) {
    return Promise.reject(new Error("Must be in MM/DD/YYYY format"));
  }

  const inputDate = moment(value, dateFormat, true);
  const currentYear = moment().year();

  if (inputDate.year() >= currentYear) {
    return Promise.reject(new Error("Invalid year"));
  }

  return Promise.resolve();
};

export const validateFutureDate = (_, value) => {
  const dateFormat = "MM/DD/YYYY";
  const date = value ? moment(value).format(dateFormat) : "";
  const isValidDate = moment(date, dateFormat, true).isValid();

  if (date && !isValidDate) {
    return Promise.reject(new Error("Must be in MM/DD/YYYY format"));
  }

  const today = moment().startOf("day");

  if (date && moment(date, dateFormat).isBefore(today)) {
    return Promise.reject(new Error("Date cannot be in the past"));
  }

  return Promise.resolve();
};

export const generateMenuItems = (routes) => {
  return routes.map((route) => ({
    label: route?.LABEL,
    type: route?.TYPE,
    key: route?.PATH,
    icon: route?.ICON,
    children: route?.CHILDREN ? generateMenuItems(route?.CHILDREN) : null,
  }));
};

export const getOnlyWeekDays = (startDate, days) => {
  let resultDate = moment(startDate).add(days, "days");
  if (resultDate.day() === 6) {
    resultDate.add(2, "days");
  } else if (resultDate.day() === 0) {
    resultDate.add(1, "days");
  }
  return resultDate;
};

export const rowDataWithIcon = (index, Icon, label, value) => {
  return (
    value &&
    value !== "" && (
      <Row key={index} gutter={[16, 16]} className="mt1">
        <Col
          xs={10}
          sm={10}
          md={12}
          lg={8}
          xl={8}
          className="flex-start-center"
        >
          <Icon className="icon18 mr075" /> {label}:
        </Col>
        <Col xs={14} sm={14} md={12} lg={16} xl={16}>
          {value}
        </Col>
      </Row>
    )
  );
};

export const centalisedSubmitBtn = (loading, text, onClickHandler, span) => {
  return (
    <Row justify={"center"} className="mt2">
      <Col xs={24} sm={12} md={12} lg={span ? span : 6} xl={span ? span : 6}>
        {onClickHandler ? (
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            disabled={loading}
            onClick={onClickHandler}
          >
            <Loader loading={loading} text={text} />
          </Button>
        ) : (
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            disabled={loading}
          >
            <Loader loading={loading} text={text} />
          </Button>
        )}
      </Col>
    </Row>
  );
};

export const getBaseUrl = () => {
  return window.location.origin;
};

export const getOpenKeys = (path) => {
  if (
    [
      ROUTES.CLINIC_ADMIN.SLEEP_LOCATIONS.PATH,
      ROUTES.CLINIC_ADMIN.SLEEP_BOOKINGS.PATH,
      ROUTES.CLINIC_ADMIN.SLEEP_MANUAL_BOOK.PATH,
      ROUTES.CLINIC_ADMIN.SLEEP_DEVICES.PATH,
    ].includes(path)
  ) {
    return ROUTES.CLINIC_ADMIN.SLEEP.PATH;
  } else if (path.includes(ROUTES.CLINIC_ADMIN.PATIENTS.PATH)) {
    return ROUTES.CLINIC_ADMIN.PATIENTS.PATH;
  } else if (
    [
      ROUTES.CLINIC_ADMIN.ACCOUNT_SETTINGS.PATH,
      ROUTES.CLINIC_ADMIN.ACCOUNT_SUBSCRIPTIONS.PATH,
      ROUTES.CLINIC_ADMIN.ACCOUNT_TRANSACTIONS.PATH,
    ].includes(path)
  ) {
    return ROUTES.CLINIC_ADMIN.ACCOUNT.PATH;
  } else if (
    [
      ROUTES.CLINIC_ADMIN.FOTONA_BOOKINGS.PATH,
      ROUTES.CLINIC_ADMIN.FOTONA_LOCATIONS.PATH,
    ].includes(path)
  ) {
    return ROUTES.CLINIC_ADMIN.FOTONA.PATH;
  } else if (
    [
      ROUTES.PROVIDER.ACTIVE_PATIENTS.PATH,
      ROUTES.PROVIDER.COMPLETED_PROGRAMS.PATH,
    ].includes(path)
  ) {
    return ROUTES.PROVIDER.PATIENTS.PATH;
  } else if (
    [
      ROUTES.PROVIDER.VITAL_ALERTS.PATH,
      ROUTES.PROVIDER.OTHER_ALERTS.PATH,
    ].includes(path)
  ) {
    return ROUTES.PROVIDER.ALERTS.PATH;
  } else if (path.includes(ROUTES.PATIENT.DASHBOARD.PATH)) {
    return ROUTES.PATIENT.DASHBOARD.PATH;
  } else if (path.includes(ROUTES.PATIENT.QUESTIONNAIRES.PATH)) {
    return ROUTES.PATIENT.QUESTIONNAIRES.PATH;
  } else if (path.includes(ROUTES.PATIENT.TIMELINES.PATH)) {
    return ROUTES.PATIENT.TIMELINES.PATH;
  } else {
    return path;
  }
};

export const disableFutureDates = (current) => {
  return (
    current &&
    (current > moment().endOf("day") ||
      current < moment().subtract(100, "years"))
  );
};

export const disabledBookingsDate = (current, weeks, enableCurentDay) => {
  const currentDate = enableCurentDay ? moment() : moment().add(1, "days");
  const endDate = currentDate.clone().add(3, "months");
  const isWeekend = current && (current.day() === 0 || current.day() === 6);

  if (weeks) {
    const twoWeeksFromNow = currentDate.clone().add(2, "weeks");
    const isWithinTwoWeeksOrPast =
      current &&
      (current.isBefore(currentDate.startOf("day")) ||
        current.isBefore(twoWeeksFromNow.startOf("day")));
    return isWithinTwoWeeksOrPast || isWeekend;
  } else {
    const isOutOfRange =
      current &&
      (current.isBefore(currentDate.startOf("day")) ||
        current.isAfter(endDate.endOf("day")));
    return isOutOfRange || isWeekend;
  }
};

export const disableWeekends = (current) => {
  return current && (current.day() === 0 || current.day() === 6);
};

export const disableWeekendsAndPastDates = (current) => {
  return (
    current &&
    (current < moment().startOf("day") ||
      current.day() === 0 ||
      current.day() === 6)
  );
};

export const disabledFotonaBookingDates = (current, isAdmin) => {
  const currentDate = moment().add(1, "days");
  const endDate = currentDate.clone().add(3, "months");
  let isOutOfRange;
  if (isAdmin) {
    isOutOfRange = current?.isBefore(currentDate.startOf("day"));
  } else {
    isOutOfRange =
      current?.isBefore(currentDate.startOf("day")) ||
      current?.isAfter(endDate.endOf("day"));
  }

  const isWeekend = current && (current.day() === 0 || current.day() === 6);
  const isMondayOrThursday =
    current && (current.day() === 1 || current.day() === 4);

  const isSecondTuesday =
    current && current.day() === 2 && Math.ceil(current.date() / 7) === 2;

  return isOutOfRange || isWeekend || isMondayOrThursday || isSecondTuesday;
};

export const convertToCamelCase = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("");
};

export const containsService = (data, services) => {
  return data.some((item) => services.includes(item.service));
};

export const patientDynamicSiderItem = (programs) => {
  const transformDataToMenuItems = (
    data,
    weather,
    questionnaire,
    timelines
  ) => {
    const menuItems = data.map((item) => {
      return {
        label: item.service === SERVICES.RING ? "SAiWELL Ring" : item.service,
        key: `/patient/dashboard/${item.clinic}/${
          item.service === SERVICES.SLIT
            ? item.service
            : convertToCamelCase(item.service)
        }`,
        icon: <FundOutlined className="menu-icon" />,
      };
    });

    let extraMenu = [];
    if (weather) {
      extraMenu.push(ROUTES.PATIENT.WEATHER);
    }
    if (questionnaire) {
      extraMenu.push(ROUTES.PATIENT.QUESTIONNAIRES);
    }
    if (timelines) {
      extraMenu.push(ROUTES.PATIENT.TIMELINES);
    }
    extraMenu = generateMenuItems(extraMenu);
    return [...menuItems, ...extraMenu];
  };

  const weather = containsService(programs, ["SLIT", "Biologics"]);
  const timelines = containsService(programs, ["Pre Post Procedure"]);
  const questionnaire = containsService(programs, [
    "SLIT",
    "Biologics",
    "Tinnitus",
    "Mental Wellness",
  ]);

  return transformDataToMenuItems(programs, weather, questionnaire, timelines);
};

export const clinicAdminDynamicSiderItem = (programs) => {
  const menuItems = programs?.map((item) => {
    return {
      label: `${item?.label} Patients`,
      key: `/clinicAdmin/patients/${
        item?.value === SERVICES.SLIT
          ? item?.value
          : convertToCamelCase(item?.value)
      }`,
      icon: <FundOutlined className="menu-icon" />,
    };
  });

  return menuItems;
};

export const displayOnlyTime = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);

    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  }
  return "";
};

export const displayOnlyHours = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);

    let hours = date.getHours();
    const period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;
    return `${hours}${period}`;
  }
  return "";
};

export const convertHeightToCm = (feet, inches) => {
  const feetInCm = feet * 30.48;
  const inchesInCm = inches * 2.54;
  const heightInCm = feetInCm + inchesInCm;
  return Math.floor(heightInCm);
};

export const convertLbsToKg = (weightInLbs) => {
  const weightInKg = weightInLbs * 0.453592;
  return Math.floor(weightInKg);
};

export const mapGraphTimeWithValue = (date, value) => {
  const parsedDate = new Date(date);
  if (isNaN(parsedDate)) {
    return ["", value];
  }

  const day = new Date(date).getDate();
  const month = new Date(date).toLocaleDateString("en-US", {
    month: "short",
  });

  const daySuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const formattedDay = `${day}${daySuffix(day)}`;
  const formattedDate = `${formattedDay} ${month}`;

  return [formattedDate, value];
};

export const getGraphTime = (date) => {
  const parsedDate = new Date(date);
  if (isNaN(parsedDate)) return;

  const day = new Date(date).getDate();
  const month = new Date(date).toLocaleDateString("en-US", {
    month: "short",
  });

  const daySuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const formattedDay = `${day}${daySuffix(day)}`;
  const formattedDate = `${formattedDay} ${month}`;

  return formattedDate;
};

export const isNativeIosAppOnly = () => {
  return window?.webkit?.messageHandlers?.uidMessageHandler;
};

const userAgent = navigator.userAgent || navigator.vendor || window.opera;
const isFlutterAgent = /flutter/i.test(userAgent);
const isFlutterApp =
  window.flutter_inappwebview !== undefined ||
  window.flutterInAppWebViewPlatformReady === true ||
  window.flutter !== undefined;

export const isAndroidOrIosApp = () => {
  return isFlutterApp || isFlutterAgent || isNativeIosAppOnly();
};

export const isFlutterAppOnly = () => {
  return isFlutterApp || isFlutterAgent;
};

export const postMessageToMobileApp = (data, handler) => {
  if (isFlutterAppOnly()) {
    window?.flutter_inappwebview?.callHandler(
      handler ? handler : "reactEventHandler",
      JSON.stringify(data)
    );
  }
  if (isNativeIosAppOnly()) {
    window?.webkit?.messageHandlers?.uidMessageHandler?.postMessage(data);
  }
};

export const getOS = () => {
  if (/iPad/i.test(userAgent)) {
    return "iPad";
  }
  if (/iPhone/i.test(userAgent)) {
    return "iPhone";
  }
  if (/iPod/i.test(userAgent)) {
    return "iPod";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/Macintosh/i.test(userAgent)) {
    return "iPad Safari";
  }
  if (/Chrome/i.test(userAgent)) {
    return "Web (Chrome)";
  }
  if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) {
    return "Web (Safari)";
  }
  if (/Firefox/i.test(userAgent)) {
    return "Web (Firefox)";
  }
  if (/Edge/i.test(userAgent)) {
    return "Web (Edge)";
  }
  if (/MSIE|Trident/i.test(userAgent)) {
    return "Web (Internet Explorer)";
  }
  return "Unknown";
};

export const getBookingType = (clinic, type) => {
  return `${clinic}-${type}`;
};

export const extractClinic = (bookingType) => {
  return bookingType?.split("-")[0];
};

export const getFormattedDateRange = (list, frequency) => {
  if (!list || list.length === 0) {
    return "";
  }

  const getTimestampFromEntry = (entry, isKeyValueFormat) => {
    return isKeyValueFormat ? Object.keys(entry)[0] : entry.startTime;
  };

  const datePatterns = [
    /^\d{4}-\d{2}-\d{2}$/, // YYYY-MM-DD (first format)
    /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\+00:00$/, // YYYY-MM-DD HH:mm:ss+00:00 (second format)
  ];

  const isKeyValueFormat = Object.keys(list[0]).some((key) =>
    datePatterns.some((pattern) => pattern.test(key))
  );

  const timestamps = list.map((entry) =>
    getTimestampFromEntry(entry, isKeyValueFormat)
  );

  if (timestamps.length > 0) {
    const sortedTimestamps = timestamps.sort(
      (a, b) => new Date(a) - new Date(b)
    );

    const formatDate = (timestamp) => {
      const date = new Date(timestamp);
      const day = date.getUTCDate();
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getUTCFullYear();
      return `${day} ${month} ${year}`;
    };

    if (frequency === "day") {
      // Use the last row of data for the day filter
      const lastDate = formatDate(
        sortedTimestamps[sortedTimestamps.length - 1]
      );
      return lastDate;
    }

    const startDate = formatDate(sortedTimestamps[0]);
    const endDate = formatDate(sortedTimestamps[sortedTimestamps.length - 1]);

    if (startDate === endDate) {
      return startDate;
    }

    const [startDay] = startDate.split(" ");
    const [endDay, endMonth, endYear] = endDate.split(" ");
    return `${startDay} - ${endDay} ${endMonth} ${endYear}`;
  }

  return "";
};

export const getFormattedDateRangeFromTimes = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return "";
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getUTCFullYear();
    return `${day} ${month} ${year}`;
  };

  const startDate = formatDate(startTime);
  const endDate = formatDate(endTime);

  if (startDate === endDate) {
    return startDate;
  }

  const [startDay] = startDate.split(" ");
  const [endDay, endMonth, endYear] = endDate.split(" ");
  return `${startDay} - ${endDay} ${endMonth} ${endYear}`;
};

export const durationToHrsAndMins = (duration) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  return `<b>${hours}</b> hrs <b>${minutes}</b> mins`;
};

export const extractCode = (phoneNumber) => {
  const digitsOnly = phoneNumber?.replace(/\D/g, "");
  return digitsOnly?.slice(-10) || null;
};
