import { ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Collapse, Row } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { postApis } from "../../../Common/Apis";
import { NOTIFICATIONS } from "../../../Common/Constants";
import { providerViewPatientAudiologyReportUrl } from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";
import useNotify from "../../../Common/Notify";
import Audiometry from "./Audiometry";
import Cognivue from "./Cognivue";
import QuickSin from "./QuickSin";

const GTData = ({ audiologyGtDetails, profileDetails }) => {
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const [loading, setLoading] = useState(false);
  const { notify } = useNotify();
  const textSpan = audiologyGtDetails?.showUpdateInsights ? 18 : 24;

  const handleRegenerate = async () => {
    await postApis(providerViewPatientAudiologyReportUrl, {
      type: "Audiology predictions",
      token: profileDetails?.token,
    })
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const items = [
    {
      key: "1",
      label: "Audiometry",
      children: (
        <Audiometry
          isMobile={isMobile}
          audiologyGtDetails={audiologyGtDetails}
          profileDetails={profileDetails}
        />
      ),
    },
    {
      key: "2",
      label: "Cognivue",
      children: (
        <Cognivue
          audiologyGtDetails={audiologyGtDetails}
          profileDetails={profileDetails}
        />
      ),
    },
    {
      key: "3",
      label: "QuickSIN",
      children: (
        <QuickSin
          audiologyGtDetails={audiologyGtDetails}
          profileDetails={profileDetails}
        />
      ),
    },
  ];

  return (
    <div>
      <Card>
        <Row justify={"space-between"} gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={textSpan} xl={textSpan}>
            If you haven't entered Audiology, Cognivue, or QuickSIN data in GT+,
            you can do so here. This step is optional, but once updated, you can
            click 'Update Insights' to view the changes. Once clicked, please
            wait for some time and then click on "View Report" in the Insights
            tab.
          </Col>
          <Col xs={24} sm={24} md={24} lg={5} xl={5}>
            {audiologyGtDetails?.showUpdateInsights && (
              <Button
                icon={<ReloadOutlined />}
                type="primary"
                onClick={handleRegenerate}
                disabled={loading}
              >
                <Loader loading={loading} text={"Update Insights"} />
              </Button>
            )}
          </Col>
        </Row>
      </Card>

      <Collapse
        expandIconPosition="end"
        items={items}
        defaultActiveKey={["1"]}
        className="fs18 mt1"
      />
    </div>
  );
};

export default GTData;
