import { DownOutlined, EyeOutlined, RollbackOutlined } from "@ant-design/icons";
import { Button, Dropdown, Select, Space, Table } from "antd";
import { USER_TYPES } from "../Constants";
import { getPageHeader } from "../Utils";

const History = ({
  bookingList,
  userType,
  getBookingDetails,
  getPatientDetails,
  locationType,
  completeStudy,
  activeTab,
  updateInsuranceStatus,
  reOrderBookings,
  staticDropdownData,
}) => {
  const columns = [
    {
      title: "Patient Name",
      dataIndex: "fullName",
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Scheduled For",
      dataIndex: "bookingDate",
    },
    {
      title: "Return Date",
      render: (_, record) => record?.returnDate || "NA",
    },
    {
      title: "Slot",
      dataIndex: "slot",
    },
    {
      title: "Type",
      dataIndex: "bookingType",
    },
    {
      title: "Booked By",
      dataIndex: "bookedBy",
    },
    {
      title: "Study Completed?",
      dataIndex: "sleepStudyCompleted",
      render: (_, record) =>
        record?.sleepStudyCompleted ? (
          "Yes"
        ) : (
          <>
            No
            <Button
              size="small"
              type="link"
              onClick={() => completeStudy(record)}
            >
              Complete Now
            </Button>
          </>
        ),
    },
    {
      title: "Insurance",
      dataIndex: "insuranceStatus",
      width: 185,
      render: (_, record) => (
        <Select
          size="small"
          value={
            record?.insuranceStatus && record?.insuranceStatus !== ""
              ? record?.insuranceStatus
              : null
          }
          placeholder="Please Select"
          options={staticDropdownData?.common?.insuranceStatus}
          className="full-width"
          onChange={(value) => updateInsuranceStatus(record, value)}
        />
      ),
    },
    {
      title: "Payment",
      dataIndex: "stripePaymentStatus",
      render: (_, record) => {
        return record?.stripePaymentStatus
          ? record?.stripePaymentStatus === "NA"
            ? record?.stripePaymentStatus
            : getPageHeader(record?.stripePaymentStatus)
          : "NA";
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <Dropdown
          className="flex-center"
          menu={{
            items: [
              {
                key: "1",
                label: "Booking Details",
                icon: <EyeOutlined />,
                onClick: () => getBookingDetails(record),
              },
              {
                key: "2",
                label: "Patient Details",
                icon: <EyeOutlined />,
                onClick: () => getPatientDetails(record),
              },
              {
                key: "3",
                label: "Re Book",
                icon: <RollbackOutlined />,
                onClick: () => reOrderBookings(record),
              },
            ].filter((item) =>
              userType === USER_TYPES.PATIENT
                ? !["2", "3"].includes(item.key)
                : item
            ),
          }}
        >
          <Button block size="small" name="historyRow">
            <Space>
              Actions
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div data-testid="sleepHistory">
      <Table
        bordered
        rowKey={(record) => `${record?.bookingID}-${record?.bookingDate}`}
        columns={
          userType === USER_TYPES.PATIENT
            ? columns.filter(
                (column) =>
                  ![
                    "fullName",
                    "bookedBy",
                    "insuranceStatus",
                    "sleepStudyCompleted",
                  ].includes(column.dataIndex)
              )
            : locationType === "fotona"
            ? columns.filter(
                (column) =>
                  ![
                    "returnDate",
                    "bookingType",
                    "insuranceStatus",
                    "sleepStudyCompleted",
                  ].includes(column.dataIndex)
              )
            : userType === USER_TYPES.CLINIC_ADMIN
            ? ["cancelled", "noshow"].includes(activeTab)
              ? columns.filter(
                  (column) =>
                    ![
                      "bookingType",
                      "insuranceStatus",
                      "sleepStudyCompleted",
                    ].includes(column.dataIndex)
                )
              : columns.filter(
                  (column) => !["bookingType"].includes(column.dataIndex)
                )
            : columns
        }
        dataSource={bookingList}
        scroll={{ x: "100%" }}
        pagination={false}
      />
    </div>
  );
};

export default History;
