import { Button, Card, Col, Row } from "antd";
import Banner from "./Banner";
import Wellness from "./Wellness";
import Weather from "./Weather";
import General from "./General";
import PrePostProcedure from "./PrePostProcedure";
import Slit from "./Slit";
import Biologics from "./Biologics";
import { useSelector } from "react-redux";
import { ROUTES, SERVICES } from "../../Common/Constants";
import Health from "./Health";
import { getApis } from "../../Common/Apis";
import { patientDashboardUrl } from "../../Common/Endpoints";
import { useEffect, useState } from "react";
import {
  containsService,
  getRemoteConfigData,
  isAndroidOrIosApp,
} from "../../Common/Utils";
import Bookings from "./Bookings";
import Audiology from "./Audiology";
import Sleep from "./Sleep";
import { useNavigate } from "react-router-dom";
import Loader from "../../Common/Loader";

const PatientDashboard = () => {
  const profileDetails = useSelector((state) => state.profile?.profileDetails);
  const enrolledPrograms = profileDetails?.dashboard;
  const [cardComponents, setCardComponents] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobileApp = isAndroidOrIosApp();
  const navigate = useNavigate();

  const getData = async (params) => {
    setLoading(true);
    try {
      const response = await getApis(patientDashboardUrl, params);
      setLoading(false);
      return response?.data;
    } catch {
      setLoading(false);
      return null;
    }
  };

  const fetchQuestionnaireData = async () => {
    const showQuestionnaire = containsService(enrolledPrograms, [
      SERVICES.SLIT,
      SERVICES.BIOLOGICS,
      SERVICES.TINNITUS,
    ]);

    if (showQuestionnaire) {
      try {
        const result = await getRemoteConfigData("QUESTIONNAIRES");
        return result;
      } catch (error) {
        return null;
      }
    }
  };

  const renderCardWithQuestionnaire = async (item, Component) => {
    const data = await getData(item);
    const staticQuestionnaires = await fetchQuestionnaireData();
    return (
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={12}
        xl={12}
        key={`${item?.clinic}-${Component.name}`}
      >
        {loading ? (
          renderLoaderCard(item)
        ) : (
          <Component
            clinic={item?.clinic}
            staticQuestionnaires={staticQuestionnaires}
            details={data}
            isMobileApp={isMobileApp}
          />
        )}
      </Col>
    );
  };

  const renderLoaderCard = (item) => {
    if (item?.service !== SERVICES.RING) {
      return (
        <Card>
          <div className="pd4 text-center">
            <Loader loading={true} />
          </div>
        </Card>
      );
    }
  };

  const renderCard = async (item, Component, span) => {
    const data = await getData(item);
    if (data) {
      return (
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={span || 12}
          xl={span || 12}
          key={`${item?.clinic}-${Component.name}`}
        >
          {loading ? (
            renderLoaderCard(item)
          ) : (
            <Component
              clinic={item?.clinic}
              details={data}
              isMobileApp={isMobileApp}
            />
          )}
        </Col>
      );
    }
  };

  const renderDataCard = async (item, Component, span) => {
    const data = await getData(item);
    return (
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={span || 12}
        xl={span || 12}
        key={`${item?.clinic}-${Component.name}`}
      >
        {loading ? (
          renderLoaderCard(item)
        ) : (
          <Component
            clinic={item?.clinic}
            details={data}
            isMobileApp={isMobileApp}
          />
        )}
      </Col>
    );
  };

  useEffect(() => {
    const fetchAndRenderCards = async () => {
      for (const item of enrolledPrograms) {
        let cardComponent;
        if (item?.service === SERVICES.WELLNESS) {
          cardComponent = await renderDataCard(item, Wellness);
        } else if (item?.service === SERVICES.PRE_POST_PROCEDURE) {
          cardComponent = await renderDataCard(item, PrePostProcedure);
        } else if (item?.service === SERVICES.AUDIOLOGY) {
          cardComponent = await renderCard(item, Audiology);
        } else if (item?.service === SERVICES.GENERAL) {
          cardComponent = await renderDataCard(item, General);
        } else if (item?.service === SERVICES.SLIT) {
          cardComponent = await renderCardWithQuestionnaire(item, Slit);
        } else if (item?.service === SERVICES.BIOLOGICS) {
          cardComponent = await renderCardWithQuestionnaire(item, Biologics);
        } else if (item?.service === SERVICES.HEALTH) {
          cardComponent = await renderCard(item, Health);
        } else if (item?.service === SERVICES.WEATHER) {
          cardComponent = await renderCard(item, Weather);
        } else if (item?.service === SERVICES.SLEEP) {
          cardComponent = await renderCard(item, Sleep);
        } else if (item?.service === SERVICES.BOOKINGS) {
          cardComponent = await renderDataCard(item, Bookings, 24);
        }

        if (cardComponent) {
          setCardComponents((prev) => [...prev, cardComponent]);
        }
      }
    };

    fetchAndRenderCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrolledPrograms]);

  return (
    <div data-testid="patient-dashboard">
      <>
        <Banner />
        <Row gutter={[32, 32]} justify="space-between" className="mt2">
          {cardComponents}
        </Row>
        {enrolledPrograms?.filter(
          (item) => ![SERVICES.SLEEP, SERVICES.HEALTH].includes(item?.service)
        )?.length === 0 && (
          <Card className="text-center grey mt2">
            You havent joined any of the service or program, click on the
            Explore button to see the options
            <Row className="flex-center mt2">
              <Col xs={24} sm={12} md={12} lg={8} xl={4}>
                <Button
                  block
                  type="primary"
                  onClick={() => navigate(ROUTES.PATIENT.PROGRAMS.PATH)}
                >
                  Explore
                </Button>
              </Col>
            </Row>
          </Card>
        )}
      </>
    </div>
  );
};

export default PatientDashboard;
