export const defaultPageSize = process.env.REACT_APP_PAGE_SIZE;

export const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const refreshTokenUrl =
  baseUrl + process.env.REACT_APP_COMMON_REFRESH_TOKEN_URL;

export const bookingsLocationsUrl =
  baseUrl + process.env.REACT_APP_BOOKINGS_LOCATIONS_URL;

export const patientVerifyPhoneUrl =
  baseUrl + process.env.REACT_APP_PATIENT_VERIFY_PHONE_URL;

export const patientVerifyEmailUrl =
  baseUrl + process.env.REACT_APP_PATIENT_VERIFY_EMAIL_URL;

export const patientVerifyMrnUrl =
  baseUrl + process.env.REACT_APP_PATIENT_VERIFY_MRN_URL;

export const patientProfileUrl =
  baseUrl + process.env.REACT_APP_PATIENT_PROFILE_URL;

export const patientProgramsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_PROGRAMS_URL;

export const patientRespiratoryVitalsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_RESPIRATORY_VITALS_URL;

export const patientSurgeryVitalsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_SURGERY_VITALS_URL;

export const patientGeneralVitalsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_GENERAL_VITALS_URL;

export const patientRegisterUrl =
  baseUrl + process.env.REACT_APP_PATIENT_REGISTER_URL;

export const patientRingUrl = baseUrl + process.env.REACT_APP_PATIENT_RING_URL;

export const patientRingDetailsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_RING_DETAILS_URL;

export const patientRingVitalsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_RING_VITALS_URL;

export const patientWellnessUrl =
  baseUrl + process.env.REACT_APP_PATIENT_WELLNESS_URL;

export const patientVerifyUserUrl =
  baseUrl + process.env.REACT_APP_PATIENT_VERIFY_USER_URL;

export const patientForgotPasswordUrl =
  baseUrl + process.env.REACT_APP_PATIENT_FORGOT_PASSWORD_URL;

export const patientAccessTokenUrl =
  baseUrl + process.env.REACT_APP_PATIENT_ACCESS_TOKEN_URL;

export const patientUpdateTokenUrl =
  baseUrl + process.env.REACT_APP_PATIENT_UPDATE_TOKEN_URL;

export const patientUpdateProfileUrl =
  baseUrl + process.env.REACT_APP_PATIENT_UPDATE_PROFILE_URL;

export const patientPesetPasswordUrl =
  baseUrl + process.env.REACT_APP_PATIENT_RESET_PASSWORD_URL;

export const patientQuestionnairesUrl =
  baseUrl + process.env.REACT_APP_PATIENT_QUESTIONNAIRES_URL;

export const patientProviderListUrl =
  baseUrl + process.env.REACT_APP_PATIENT_PROVIDER_LIST_URL;

export const patientProgramProvidersUrl =
  baseUrl + process.env.REACT_APP_PATIENT_PROGRAM_PROVIDERS_URL;

export const patientTimelineUrl =
  baseUrl + process.env.REACT_APP_PATIENT_TIMELINE_URL;

export const patientSupportUrl =
  baseUrl + process.env.REACT_APP_PATIENT_SUPPORT_URL;

export const patientHealthUrl =
  baseUrl + process.env.REACT_APP_PATIENT_HEALTH_URL;

export const patientHealthSourcesUrl =
  baseUrl + process.env.REACT_APP_PATIENT_HEALTH_SOURCES_URL;

export const patientHealthDetailsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_HEALTH_DETAILS_URL;

export const patientDashboardUrl =
  baseUrl + process.env.REACT_APP_PATIENT_DASHBOARD_URL;

export const patientRespiratoryBestPefGraphUrl =
  baseUrl + process.env.REACT_APP_PATIENT_RESPIRATORY_BEST_PEF_GRAPH_URL;

export const patientWeatherUrl =
  baseUrl + process.env.REACT_APP_PATIENT_WEATHER_URL;

export const patientDeviceDetailsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_DEVICE_DETAILS_URL;

export const patientDeviceOrderUrl =
  baseUrl + process.env.REACT_APP_PATIENT_DEVICE_ORDER_URL;

export const patientVialsOrderUrl =
  baseUrl + process.env.REACT_APP_PATIENT_VIALS_ORDER_URL;

export const patientOrderVialsQuestionnaireUrl =
  baseUrl + process.env.REACT_APP_PATIENT_ORDERS_VIALS_QUESTIONNAIRE_URL;

export const patientBookingsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_BOOKINGS_URL;

export const patientTinnitusUrl =
  baseUrl + process.env.REACT_APP_PATIENT_TINNITUS_URL;

export const patientTinnitusWeeklyWellnessUrl =
  baseUrl + process.env.REACT_APP_PATIENT_TINNITUS_WEEKLY_WELLNESS_URL;

export const patientWellnessWeeklyUrl =
  baseUrl + process.env.REACT_APP_PATIENT_WELLNESS_WEEKLY_URL;

export const patientFotonaBookingsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_FOTONA_BOOKINGS_URL;

export const patientFotonaAvailableSlotsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_FOTONA_AVAILABLE_SLOTS_URL;

export const patientBookingsListUrl =
  baseUrl + process.env.REACT_APP_PATIENT_BOOKINGS_LIST_URL;

export const patientBookingsSendDocumentUrl =
  baseUrl + process.env.REACT_APP_PATIENT_BOOKINGS_SEND_DOCUMENT_URL;

export const patientBookingsSignedStatusUrl =
  baseUrl + process.env.REACT_APP_PATIENT_BOOKINGS_SIGNED_STATUS_URL;

export const patientBookingsViewUrl =
  baseUrl + process.env.REACT_APP_PATIENT_BOOKINGS_VIEW_URL;

export const patientRespiratoryEpisodeDetailsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_RESPIRATORY_EPISODE_DETAILS_URL;

export const patientSurgeryEpisodeDetailsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_SURGERY_EPISODE_DETAILS_URL;

export const patientGeneralEpisodeDetailsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_GENERAL_EPISODE_DETAILS_URL;

export const patientPaymentsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_PAYMENTS_URL;

export const patientProfilePaymentsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_PROFILE_PAYMENTS_URL;

export const patientPaymentTransactionsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_PAYMENT_TRANSACTIONS_URL;

export const patientPaymentChargeUrl =
  baseUrl + process.env.REACT_APP_PATIENT_PAYMENT_CHARGE_URL;

export const patientPaymentAutoChargeUrl =
  baseUrl + process.env.REACT_APP_PATIENT_PAYMENT_AUTO_CHARGE_URL;

export const patientPaymentClinicCardsUrl =
  baseUrl + process.env.REACT_APP_PATIENT_PAYMENT_CLINIC_CARDS_URL;

export const publicContactUsUrl =
  baseUrl + process.env.REACT_APP_PUBLIC_CONTACT_US_URL;

export const providerVerifyPhoneUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VERIFY_PHONE_URL;

export const providerEmailLoginUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_EMAIL_LOGIN_URL;

export const providerResendEmailOtpUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_RESEND_EMAIL_OTP_URL;

export const providerProfileUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_PROFILE_URL;

export const providerRegisterUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_REGISTER_URL;

export const providerVerifyUserUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VERIFY_USER_URL;

export const providerForgotPasswordUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_FORGOT_PASSWORD_URL;

export const providerAccessTokenUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_ACCESS_TOKEN_URL;

export const providerPesetPasswordUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_RESET_PASSWORD_URL;

export const providerUpdateProfileUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_UPDATE_PROFILE_URL;

export const providerNotificationUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_NOTIFICATION_URL;

export const providerDashboardUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_DASHBOARD_URL;

export const providerResolveAlertUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_RESOLVE_ALERT_URL;

export const providerResolveAllAlertsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_RESOLVE_ALL_ALERTS_URL;

export const providerDoctorsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_DOCTORS_URL;

export const providerActivePatientsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_ACTIVE_PATIENTS_URL;

export const providerCompletedPatientsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_COMPLETED_PATIENTS_URL;

export const providerVitalAlertsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VITAL_ALERTS_URL;

export const providerOtherAlertsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_OTHER_ALERTS_URL;

export const providerClaimsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_CLAIMS_URL;

export const providerVialsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIALS_URL;

export const providerCategoriesUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_CATEGORIES_URL;

export const providerVitalNamesUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VITAL_NAMES_URL;

export const providerViewPatientProfileUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_PROFILE_URL;

export const providerViewPatientAlertsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_ALERTS_URL;

export const providerViewPatientVitalsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_VITALS_URL;

export const providerViewPatientGraphsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_GRAPHS_URL;

export const providerViewPatientEpisodeUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_EPISODE_URL;

export const providerViewPatientUnlinkDeviceUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_UNLINK_DEVICE_URL;

export const providerViewPatientTimelinesUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_TIMELINES_URL;

export const providerViewPatientFeNoUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_FENO_URL;

export const providerViewPatientAudiologyUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_AUDIOLOGY_URL;

export const providerViewPatientWellnessUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_WELLNESS_URL;

export const providerViewPatientHealthDataUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_HEALTHDATA_URL;

export const providerViewPatientClaimsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_CLAIMS_URL;

export const providerViewPatientVialsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_VIALS_URL;

export const providerViewPatientQuestionnairesUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_QUESTIONNAIRES_URL;

export const providerViewPatientEmrDataUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_EMR_DATA_URL;

export const providerSupportUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_SUPPORT_URL;

export const providerViewPatientReportsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_REPORTS_URL;

export const providerViewPatientSearchMedicinesUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_SEARCH_MEDICINES_URL;

export const providerViewPatientBestPefGraphUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_BEST_PEF_GRAPH_URL;

export const providerViewPatientUnregisterUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_UNREGISTER_URL;

export const providerViewPatientTinnitusQuestionnairesUrl =
  baseUrl +
  process.env.REACT_APP_PROVIDER_VIEW_PATIENT_TINNITUS_QUESTIONNAIRES_URL;

export const providerViewPatientTinnitusRiskUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_TINNITUS_RISK_URL;

export const providerViewPatientReferralUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_REFERRAL_URL;

export const providerViewPatientAudiologyGtDataUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_AUDIOLOGY_GTDATA_URL;

export const providerViewPatientAudiologyReportUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_AUDIOLOGY_REPORT_URL;

export const providerViewPatientAudiologyPaymentsUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_AUDIOLOGY_PAYMENTS_URL;

export const providerViewPatientAudiologyRemarksUrl =
  baseUrl + process.env.REACT_APP_PROVIDER_VIEW_PATIENT_AUDIOLOGY_REMARKS_URL;

export const deviceDetailsUrl =
  baseUrl + process.env.REACT_APP_DEVICE_DETAILS_URL;

export const deviceOrderUrl = baseUrl + process.env.REACT_APP_DEVICE_ORDER_URL;

export const clinicAdminSupportUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_SUPPORT_URL;

export const clinicAdminRegisterUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_REGISTER_URL;

export const clinicAdminEmailLoginUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_EMAIL_LOGIN_URL;

export const clinicAdminVerifyPhoneUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_VERIFY_PHONE_URL;

export const clinicAdminForgotPasswordUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_FORGOT_PASSWORD_URL;

export const clinicAdminResetPasswordUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_RESET_PASSWORD_URL;

export const clinicAdminVerifyUserUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_VERIFY_USER_URL;

export const clinicAdminAccessTokenUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_ACCESS_TOKEN_URL;

export const clinicAdminProvidersUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_PROVIDERS_URL;

export const clinicAdminResendEmailOtpUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_RESEND_EMAIL_OTP_URL;

export const clinicAdminProfileUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_PROFILE_URL;

export const clinicAdminAddProviderUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_ADD_PROVIDER_URL;

export const clinicAdminPatientsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_PATIENTS_URL;

export const clinicAdminCompletedPatientsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_COMPLETED_PATIENTS_URL;

export const clinicAdminDashboardUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_DASHBOARD_URL;

export const clinicAdminDeviceDetailsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_DEVICE_DETAILS_URL;

export const clinicAdminSleepAddLocationUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_SLEEP_ADD_LOCATION_URL;

export const clinicAdminFotonaAddLocationUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_FOTONA_ADD_LOCATION_URL;

export const clinicAdminSleepDeviceDetailsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_SLEEP_DEVICE_DETAILS_URL;

export const clinicAdminSleepBookingsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_SLEEP_BOOKINGS_URL;

export const clinicAdminSleepDevicesUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_SLEEP_DEVICES_URL;

export const clinicAdminSleepDevicesListUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_SLEEP_DEVICES_LIST_URL;

export const clinicAdminSleepLocationDetailsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_SLEEP_LOCATION_DETAILS_URL;

export const clinicAdminFotonaLocationDetailsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_FOTONA_LOCATION_DETAILS_URL;

export const clinicAdminSleepSlotsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_SLEEP_SLOTS_URL;

export const clinicAdminSleepBookSlotUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_SLEEP_BOOK_SLOT_URL;

export const clinicAdminFotonaBookingsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_FOTONA_BOOKINGS_URL;

export const clinicAdminFotonaSlotsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_FOTONA_SLOTS_URL;

export const clinicAdminWebinarBookingsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_WEBINAR_BOOKINGS_URL;

export const clinicAdminWebinarSlotsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_WEBINAR_SLOTS_URL;

export const clinicAdminProfileMetaUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_PROFILE_META_URL;

export const clinicAdminTransactionsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_TRANSACTIONS_URL;

export const clinicAdminSettingsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_SETTINGS_URL;

export const clinicAdminSettingsProductUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_SETTINGS_PRODUCT_URL;

export const clinicAdminSubscriptionsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_SUBSCRIPTIONS_URL;

export const clinicAdminSubscriptionsCardsUrl =
  baseUrl + process.env.REACT_APP_CLINIC_ADMIN_SUBSCRIPTIONS_CARDS_URL;
