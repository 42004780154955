import { Card, Col, Row, Button, Form, Empty } from "antd";
import { useEffect, useState } from "react";
import { useApiLoader } from "../../../Common/ApiLoaderContext";
import { getApis, postApis } from "../../../Common/Apis";
import { NOTIFICATIONS } from "../../../Common/Constants";
import {
  clinicAdminSettingsProductUrl,
  clinicAdminSettingsUrl,
} from "../../../Common/Endpoints";
import useNotify from "../../../Common/Notify";
import { getRemoteConfigData } from "../../../Common/Utils";
import Product from "./Product";

const AccountSettings = () => {
  const [accountDetails, setAccountDetails] = useState();
  const [images, setImages] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const { notify } = useNotify();

  const getAccountDetails = async () => {
    setShowLoader(true);
    await getApis(clinicAdminSettingsUrl)
      .then((response) => {
        setShowLoader(false);
        setAccountDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getRemoteConfigData("IMAGES").then((result) => {
      setImages(result?.clinicAdmin?.account?.settings);
    });
    getAccountDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
    form.setFieldsValue({
      ...accountDetails?.products?.[0],
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    await postApis(clinicAdminSettingsProductUrl, values)
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    !showLoader && (
      <>
        <Row gutter={[16, 16]} justify="space-evenly">
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Card title={"Available Kits"}>
              {(!accountDetails || !accountDetails?.kits) && (
                <Empty
                  description="Please contact support@saigeware.com to request your kits."
                  imageStyle={{
                    height: 70,
                  }}
                />
              )}
              {accountDetails?.kits?.map((item, index) => {
                return (
                  <div key={index} className="grey-fill pd1">
                    <Row gutter={[16, 16]}>
                      <Col
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={8}
                        className="flex-start-center"
                      >
                        <img alt={item?.name} src={images?.kit} width="100%" />
                      </Col>
                      <Col xs={18} sm={18} md={18} lg={18} xl={16}>
                        <div className="bold fs18 blue flex-start-center">
                          {item?.name}
                        </div>
                        <div className="mt1">
                          <span className="bold">Kit Provided On:</span>{" "}
                          <span>{item?.date}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {accountDetails?.kits && (
              <Card
                title={"Products"}
                extra={[
                  !accountDetails ||
                  !accountDetails?.products ||
                  accountDetails?.products?.length < 1 ? (
                    <Button
                      key="add"
                      type="primary"
                      onClick={() => showModal()}
                    >
                      Add
                    </Button>
                  ) : (
                    <Button
                      key="edit"
                      type="primary"
                      onClick={() => showModal()}
                    >
                      Edit
                    </Button>
                  ),
                ]}
              >
                {!accountDetails || !accountDetails?.products ? (
                  <Empty
                    imageStyle={{
                      height: 70,
                    }}
                    description="If you would like SAiWELL to charge patients for their GT+insigths, add the amount that needs to be charged here."
                  />
                ) : (
                  accountDetails?.products && (
                    <div>
                      If you would like SAiWELL to charge patients for their
                      GT+insigths, add the amount that needs to be charged here.
                    </div>
                  )
                )}

                {accountDetails?.products?.map((item, index) => {
                  return (
                    <div key={index} className="grey-fill pd1 mt1">
                      <div>
                        <span className="bold">Product:</span>
                        <span className="black ml05">{item?.product}</span>
                      </div>
                      <div className="mt05">
                        <span className="bold">Amount:</span>
                        <span className="black ml05">${item?.amount}</span>
                      </div>
                      <div className="mt05">
                        <span className="bold">Description:</span>
                        <span className="black ml05">{item?.description}</span>
                      </div>
                    </div>
                  );
                })}
              </Card>
            )}
          </Col>
        </Row>
        {isModalOpen && (
          <Product
            form={form}
            loading={loading}
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />
        )}
      </>
    )
  );
};

export default AccountSettings;
