import React, { useEffect, useState } from "react";
import { Col, Flex, Input, Row, Select, Table } from "antd";
import { getApis } from "../../Common/Apis";
import {
  providerCategoriesUrl,
  providerActivePatientsUrl,
  defaultPageSize,
} from "../../Common/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { BUTTONS, SERVICES, ROUTES } from "../../Common/Constants";
import {
  setCategories,
  setPatientFilters,
} from "../../../Reducers/filterSlice";
import { addViewButton } from "../../Common/Utils";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { Link } from "react-router-dom";
const { Search } = Input;

const ProviderActivePatients = () => {
  const [patientList, setPatientList] = useState();
  const [patients, setPatients] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [searchQuery, setSearchQuery] = useState();
  const [paginationDetails, setPaginationDetails] = useState({
    current: 1,
    pageSize: defaultPageSize,
  });
  const [fromPage, setFromPage] = useState(1);
  const [forward, setForward] = useState(true);
  const filters = useSelector((state) => state?.filter?.patients);
  const categories = useSelector((state) => state?.filter?.categories);
  const defaultDoctor = useSelector((state) => state?.filter?.defaultDoctor);
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      sortDirections: ["descend"],
      render: (_, record) => (
        <Link
          target="_blank"
          to={`${ROUTES.PROVIDER.PATIENT_DETAILS.PATH}${record.phoneNumber}/${
            filters?.category || categories?.[0]
          }/${record.firstName} ${record.lastName}`}
        >
          {record.firstName} {record.lastName}
        </Link>
      ),
    },
    {
      title: "Date Of Birth",
      dataIndex: "dateOfBirth",
    },
    {
      title: "MRN",
      dataIndex: "mrn",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Contact",
      dataIndex: "phoneNumber",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) =>
        addViewButton(
          `${ROUTES.PROVIDER.PATIENT_DETAILS.PATH}${record.phoneNumber}/${
            filters?.category || categories?.[0]
          }/${record.firstName} ${record.lastName}`,
          BUTTONS.VIEW
        ),
    },
  ];

  const getDoctorCategories = async () => {
    await getApis(
      providerCategoriesUrl,
      defaultDoctor ? { doctor: defaultDoctor } : {}
    )
      .then((response) => {
        dispatch(setCategories(response?.data?.categories));
        dispatch(
          setPatientFilters({ category: response?.data?.categories[0] })
        );
        setPaginationDetails({
          forward: true,
          current: 1,
          category: response?.data?.categories[0],
          pageSize: paginationDetails.pageSize,
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!categories) {
      setPatientList();
      getDoctorCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDoctor]);

  useEffect(() => {
    getPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationDetails]);

  const handleSearch = async (query) => {
    setPatientList();
    setSearchQuery(query);
    setFromPage(1);
    setForward(true);
    setPaginationDetails({
      forward: true,
      current: 1,
      searchBy: query,
      category: filters?.category || categories?.[0],
      pageSize: paginationDetails.pageSize,
    });
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getPatients = async () => {
    if (filters?.category || categories?.[0]) {
      setShowLoader(true);
      const defaultParams = {
        forward: forward,
        doctor: defaultDoctor,
        searchBy: searchQuery,
        service: filters?.category || categories?.[0],
        paginationSize: paginationDetails.pageSize,
        firstDate: patientList?.firstDate,
        lastDate: patientList?.lastDate,
      };
      if (searchQuery === "") {
        delete defaultParams["searchBy"];
      }
      await getApis(providerActivePatientsUrl, defaultParams)
        .then((response) => {
          setShowLoader(false);
          setPatientList(response?.data);
          setPatients(response?.data?.patients);
        })
        .catch(() => setShowLoader(false));
    }
  };

  const handlePageChange = (pagination) => {
    setFromPage(pagination.current);
    setForward(pagination.current > fromPage);
    setPaginationDetails({
      ...paginationDetails,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const handleCategoryChange = (value) => {
    setPatientList();
    setFromPage(1);
    setForward(true);
    dispatch(setPatientFilters({ category: value }));
    setPaginationDetails({
      forward: true,
      current: 1,
      category: value,
      pageSize: paginationDetails.pageSize,
    });
  };

  return (
    !showLoader && (
      <div data-testid="providers-active-patients">
        <Row justify={"space-between"} gutter={[16, 8]}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="flex-start-center"
          >
            {defaultDoctor && (
              <Flex wrap="wrap" justify={"start"}>
                <div className="bold mr05">Provider: </div>
                <div>{defaultDoctor}</div>
              </Flex>
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Select
              name="category"
              size="large"
              value={filters?.category}
              placeholder="Select Category"
              options={categories?.map((item) => {
                return { label: item, value: item };
              })}
              className="full-width"
              onChange={handleCategoryChange}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Search
              allowClear
              value={searchQuery}
              placeholder="Search by First Name or MRN"
              onSearch={handleSearch}
              onChange={handleSearchInputChange}
              size="large"
              className="full-width search"
            />
          </Col>
        </Row>
        <Row className="mt1">
          <Col span={24}>
            <Table
              rowKey={(record) => record.mrn}
              columns={
                [SERVICES.GENERAL, SERVICES.SLIT, SERVICES.BIOLOGICS].includes(
                  filters?.category
                )
                  ? columns.filter(
                      (column) => column.dataIndex !== "surgeryDate"
                    )
                  : columns
              }
              dataSource={patients}
              scroll={{ x: "100%" }}
              onChange={handlePageChange}
              pagination={{
                current: paginationDetails.current,
                pageSize: paginationDetails.pageSize,
                total: patientList?.totalPatients,
                showSizeChanger: false,
                showQuickJumper: false,
                hideOnSinglePage: true,
                showTotal: (total, range) => (
                  <div className="mt025">
                    Total <b>{total}</b> Items
                  </div>
                ),
              }}
            />
          </Col>
        </Row>
      </div>
    )
  );
};
export default ProviderActivePatients;
